<template>
  <div class="workplace">
    <div
      class="workplace__content col-11 col-sm-10"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <h3>{{ workplace.title }}</h3>
      <p>{{ workplace.sub }}</p>
    </div>
    <div
      class="workplace__container col-11 col-sm-10 m-auto d-flex"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="workplace__items" v-for="(items, index) in job" :key="index">
        <div
          class="workplace__item"
          v-for="(item, indx) in items"
          :key="indx"
          :style="{ background: item.colorBg }"
        >
          <img v-lazy="item.icon" alt="icon" />
          <p>{{ item.sub }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'workplace',
  data() {
    return {
      okrsMobile: '/images/people/workplace-mobile.png',
      workplace: this.$t('people').workplace,
      job: [
        [
          {
            sub: this.$t('people').workplace.cards.subOkrs,
            icon: '/images/icons/people/okrs.svg',
          },
        ],
        [
          {
            sub: this.$t('people').workplace.cards.subParty,
            icon: '/images/icons/people/happy.svg',
          },
          {
            sub: this.$t('people').workplace.cards.subHeart,
            icon: '/images/icons/people/heart.svg',
          },
        ],
        [
          {
            sub: this.$t('people').workplace.cards.subEar,
            icon: '/images/icons/people/ear.svg',
          },
          {
            sub: this.$t('people').workplace.cards.subGroup,
            icon: '/images/icons/people/group.svg',
          },
        ],
      ],
    };
  },
  watch: {
    lang() {
      this.workplace = this.$t('people').workplace;
      this.job = [
        [
          {
            sub: this.$t('people').workplace.cards.subOkrs,
            icon: '/images/icons/people/okrs.svg',
          },
        ],
        [
          {
            sub: this.$t('people').workplace.cards.subParty,
            icon: '/images/icons/people/happy.svg',
          },
          {
            sub: this.$t('people').workplace.cards.subHeart,
            icon: '/images/icons/people/heart.svg',
          },
        ],
        [
          {
            sub: this.$t('people').workplace.cards.subEar,
            icon: '/images/icons/people/ear.svg',
          },
          {
            sub: this.$t('people').workplace.cards.subGroup,
            icon: '/images/icons/people/group.svg',
          },
        ],
      ];
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
};
</script>

<style lang="scss" scroped>
img {
  object-fit: cover;
}
.workplace {
  width: 100%;
  padding: 50px 0;
  position: relative;
  z-index: 0;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, #ff6e26 0%, #ffe6d8 100%);
    transform: rotate(-30deg);
    width: 130px;
    height: 130px;
    border-radius: 50%;
    bottom: 360px;
    left: -56px;
    z-index: -1;
  }
  &::before {
    content: '';
    position: absolute;
    background: #ffeae0;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    top: 82px;
    right: -56px;
    z-index: -1;
  }
}
.workplace__content {
  max-width: 752px;
  margin: 20px auto 40px;
  position: relative;
  z-index: 0;
  text-align: center;

  h3 {
    font-weight: 700;
    font-size: 40px;
    color: var(--blue-text);
    font-weight: 700;
    margin-bottom: 16px;
  }
  & > p {
    font-weight: 400;
    font-size: 18px;
    color: var(--grey-text);
    margin: 0;
  }
}
.workplace__container {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &::after {
    position: absolute;
    content: '';
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 600px;
    border: 8px solid var(--orange);
    border-radius: 50%;
    z-index: -1;
  }
}
.workplace__items {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 100px;
}
.workplace__items:first-child {
  gap: 80px;
}
.workplace__items:nth-child(2) {
  gap: 300px;
}
.workplace__items:nth-child(3) {
  margin-top: 100px;
}
.workplace__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 20px 30px;
  width: 320px;
  text-align: center;
  border-radius: 10px;
  background-color: white;
  & > img {
    width: 100px;
    height: 100px;
  }
  h3 {
    font-weight: 700;
    font-size: 21px;
    color: var(--blue-dark);
    margin: 0;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    color: var(--grey-text);
    margin: 0;
  }
}

@media (max-width: 1280px) {
  .workplace__item {
    height: auto;
    width: 45%;
  }
}
@media (max-width: 1024px) {
  .workplace__container {
    gap: 24px;
    &::after {
      display: none;
    }
  }
  .workplace__items {
    flex-direction: column-reverse;
    align-items: center;
    gap: 24px;
    margin: 0;
  }
  .workplace__items:first-child,
  .workplace__items:nth-child(2) {
    flex-direction: column;
    gap: 24px;
  }
  .workplace__items:nth-child(3) {
    margin-top: 0;
  }
  .workplace__item {
    width: 100%;
    background-color: transparent;
  }
}
@media (max-width: 768px) {
  .workplace__content {
    width: 80%;
    h3 {
      font-size: 32px;
    }
  }
  .workplace__items {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
